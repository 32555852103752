import React,{Suspense, lazy, useEffect, useState} from "react";
import { HashRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Category from "./Components/page2/";
import ZohoSetUp from "./Components/zohoSource";
import ZohoSync from "./Components/zohoSync";
import SalesforceSetUp from "./Components/salesforceSource";
import SalesforceSync from "./Components/salesforceSync";
import SFActitvitiesSetUp from "./Components/SFActivitiesSource";
import SFActivitiesSync from "./Components/sfActivitiesSync";
import functions from "./api/auth"
import {
  Button,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import Header from "./Components/Header";
import HomePage from "./Components/home";
import ZohoCallback from "./Components/zohoSource/zohoCallback";
import SalesforceCallback from "./Components/salesforceSource/salesforceCallback";
import SFActivitiesCallback from "./Components/SFActivitiesSource/salesforceCallback";
import ControlledAccordions from "./Components/attributeSetup/accordion";
import UITheme from "@bamboobox/b2-theme";
import CircularProgress from '@material-ui/core/CircularProgress';
import "./routes.css"
import Redirect from "./Components/redirection/redirect";
import panda from './panda.png';
import { toast } from "react-toastify";
import "../src/Components/Header/toast.css"
import { Alert } from "@mui/material";
import { NoAccess } from "./Components/NoAccess";
import { CATEGORIES } from "./Components/page2/constants";
import { AlertDialog } from "./Components/logoutDialogBox/alertDialog";
import { LogoutDialog } from "./Components/logoutDialogBox/logoutDialog";
const App = lazy(()=>import("./pages/App"));

const tenantIds = window._env_.PARENTTENANT_IDS.split(',')

const checkurlcampaign=()=>{
  if(tenantIds.includes(localStorage.getItem('b2bTenantId')))  return 'parentCamapaign';
  return ''
}

function ContentProvider({ component, background }) {
  return component;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function IframeComponent({ url, extra_param, icp }) {
  let url1;
    if (url === window._env_.CAMPAIGN_HUB){
      let append = checkurlcampaign();
      url1 = url + append +  unescape(extra_param) + (extra_param.includes("?")?"&icp=":"?icp=") + escape((icp || ''));
    }
    else{
  url1 = url + unescape(extra_param) + (extra_param.includes("?")?"&icp=":"?icp=") + escape((icp || ''));
    }
    url = url1
  const handleLoad = (event) => {
    event.target.className = "iframe";
  };
  useEffect(() => {
    document.getElementById("iframecomponent").className = "iframeloading";
  },[url]);
  return (
    <div id="contentframe">
      {
        (icp === null || icp === undefined) ? (<div style={{ position: "absolute", top: "50%", left: "50%", translate: "transform(-50%,-50%)" }}><CircularProgress style={{color:"#21DA9C"}} /></div>) : (
          <iframe onLoad={handleLoad} id="iframecomponent" width="100%" height="100%" src={url} frameBorder="0" allowFullScreen />
        )
      }
    </div>
  );
}

const PandaImage = () => {
  return (
    <div 
    >
        <img style={{
        width: '278.5px',
        height: '223.17px',
        left: '520.25px',
        top: '80.41px'
      }} src={panda} alt="No-Access"/>
    </div>
  )
}

function NoAccessComponent(props) {
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });
  const history = useHistory();
  useEffect(()=>{
    let icpSelected = props?.icpList?.filter(icp=>icp?.id?.icpName===props?.globalIcp);
    if(props?.globalIcp && props?.icpList?.length>0 && (icpSelected?.[0]?.isPublished === 'true' || icpSelected?.[0]?.isPublished === true)){
      history.goBack();
    }
  },[props.globalIcp,props.icpList])
  return (
    <div id="contentframecustom">
        <PandaImage/>
        
<div id="textboxcustom">
        <Typography variant="h5" style={{fontFamily: "Inter",
fontSize: '24px',
fontWeight: '600',
lineHeight: '32px',
letterSpacing: '0px',
textAlign: 'center',
color: "#CACACA"
}}>Oops, Looks like you’ve lost your way</Typography>
<Typography variant="h6" style={{
  fontFamily: "Inter",
  fontSize: '16px',
  fontWeight: '800',
  lineHeight: '36px',
  letterSpacing: '0px',
  textAlign: 'center',
  color: "#CACACA"
  }}>You don’t have access to this page, contact your administrator.</Typography>
    </div></div>
  );
}

window.addEventListener('message', function (event) {
  if (event.data == "logout-543") {
    functions.logout();
  }
}, true);

function RoutesManager({isMenuLoaded,isParent}) {
  const [token, setToken] = useState("");
  let permissions = [];
  permissions = JSON.parse(localStorage.getItem("b2Permissions"));
  const acc_contact_Creation = `${window._env_.ACC_CONTACT_CREATION}`;
  const unifiedAccountprofileUrl = `${window._env_.UNIFIED_PROFILE}`;
  const campaignHubUrl= window._env_.CAMPAIGN_HUB;
  const ICpScoreInfoUrl = `${window._env_.ICP_SCORE}`;
  const enrichmentQueue = `${window._env_.ENRICHMENT_QUEUE}`;
  const webhooksUrl = `${window._env_.WEBHOOKS_URL}`;
  const accountListView = `${window._env_.ACCOUNT_LIST_VIEW}`;
  const engagementScoreInfoUrl = `${window._env_.ENGAGEMENT_SCORE}`;
  const webFormUrl = `${window._env_.WEB_FORM}`;
  const funnelUrl = `${window._env_.FUNNEL_REPORT}`;
  const newDashboardUrl = `${window._env_.NEW_DASHBOARD_VIEW}`;
  const trendUrl = `${window._env_.TREND_REPORT}`;
  const accountSummaryUrl = `${window._env_.ACCOUNT_SUMMARY_REPORT}`;
  const campaignManagerUrl = `${window._env_.CAMPAIGN_MANAGER}`;
  const exportManagerUrl = `${window._env_.EXPORT_MANAGER}`;
  const integrationSetupUrl = `${window._env_.INTEGRATION_SETUP}`;
  const monitoring_module = `${window._env_.MONITORING_MODULE_URL}`;
  const manualDataUpload = `${window._env_.MANUAL_DATA_UPLOAD}`;
  const b2useradminUrl = `${window._env_.B2USERADMIN_URL}`;
  const b2analyticsreportsUrl = `${window._env_.B2ANALYTICSREPORTS_URL}`;
  const b2manualuploadUrl = `${window._env_.B2MANUALUPLOAD_URL}`;
  const b2scoringUrl = `${window._env_.B2SCORING_URL}`;
  const b2prospectUrl = `${window._env_.B2PROSPECT_URL}`;
  const adminSettings=`${window._env_.USERADMIN_URL}`
  const NoAccessUrl=`https://test.bamboobox.in:3000/#/no-access`;
  const history = useHistory();
  let query = useQuery();
  let params_url = query.get("params") || "";
  let icp = query.get("icp") || "";
  const [globalIcp, setGlobalIcp] = useState('' || icp)
  const [icpList, setIcpList] = React.useState([])
  const [lastLoginTime,setLastLoginTime]=useState('');
  const [isAuth,setIsAuth]=useState(false);
  const [isAuthenticated,setIsAuthenticated]=useState(false);
  const [menuOptions,setMenuOptions]=useState(CATEGORIES);
  const [isNewDashboard, setIsNewDashboard] = useState(undefined);
  const [alertDialog, setAlertDialog] = useState(false);
  const [logoutDialog, setLogoutDialog] = useState(false);
  // const [tenantImage,setTenantImage]=useState("");
  
  
  const getLastLoginDate=()=>{
    const timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(timeZone);
    fetch(`${window._env_.AUTH_URL}/users/get-last-login`, {
        method: "GET",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((loginData) => { 
          if (loginData.success) {
            const time=new Date((typeof loginData.data === "string" ? new Date(loginData.data) : loginData.data).toLocaleString("en-US", {timeZone: timeZone})); 
            console.log("time",time.toString())
            setLastLoginTime(time.toString())
          }
          else{
            console.log(loginData.error);
          }
        })
        .catch((error)=>{
          console.log("ignore Error",error);
        });
  }

  function logOut() {
    //sessionStorage.setItem('loggedIn','false');
    fetch(`${window._env_.AUTH_URL}/users/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.success) {
          localStorage.clear();
          sessionStorage.clear();
          history.push("/");
        }
      });
    localStorage.removeItem("b2bToken");
    localStorage.removeItem('b2LoggedIn');
    localStorage.removeItem("b2bPermission");
    localStorage.removeItem("tenantImage");
    localStorage.removeItem("lastActivityTime");
    //sessionStorage.clear();
    //setLastLoginTime("");
    console.log("logged out")
  }

  async function getDashboardUrl() {
    const tenantId = localStorage.getItem("b2bTenantId");
    const options = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenantId: tenantId,
        tool: "dashboard",
        keys: ["new-dashboard"],
      }),
    };
    await fetch(`${window._env_.TENANT_CONFIG_API}/tenant/get-tenant-config`, options)
      .then((res) => res.json())
      .then((result) => {
        if (result?.[0]?.configVal === "true") {
          console.log("new dashboard will be shown");
          return setIsNewDashboard(true);
        } else if(isNewDashboard){
          return setIsNewDashboard(false);
        }

        return setIsNewDashboard(false);
      })
      .catch((e) => {
        setIsNewDashboard("Error");
        console.error(e);
      });
  }

  /* keep tracking the inactivity */
  function fetchReissueToken() {
    try{
      fetch(`${window._env_.AUTH_URL}/users/reissue-jwt-token`, {
        method: "POST",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            let decodedToken = jwt_decode(data.token);
            localStorage.setItem("b2bToken", data.token);
            localStorage.setItem("b2iat", decodedToken?.iat);
            localStorage.setItem("b2exp", decodedToken?.exp);
            localStorage.setItem("b2Permissions", JSON.stringify(decodedToken.permissions));
            return true;
          } else {
            logOut();
          }
        });
        return false;
    }catch(e){
      console.error("Error occured while fetching new token", e);
      logOut();
      return false;
    }
  }

  function keepTracking() {
    window.trackActivity();
    if (window.activityInterval) clearInterval(window.activityInterval);
    window.activityInterval = setInterval(() => {
      const lastActivityTime = +localStorage.getItem("lastActivityTime");
      const now = new Date().getTime() / 1000;
      const b2exp = +localStorage.getItem("b2exp");
      /* re-issue token > 10 min before expiry */
      if ((lastActivityTime + 1800) > now && (now + 600) > b2exp) {
        fetchReissueToken()
      }

      /* logout alert > 5min before actual logout */
      if (now - lastActivityTime >= 1500) {
        setAlertDialog(true);
        console.log("lastActivityTime: logout alert triggered");
      }
      /* logout > if inactive more than 30 min */
      if (now - lastActivityTime >= 1800) {
        setLogoutDialog(true);
        console.log("lastActivityTime: logout due to inactivity.");
      }
      console.log("lastActivityTime", now, lastActivityTime, b2exp, now - lastActivityTime);
    }, 60000); /* every mins */
  }

  keepTracking();

  useEffect(()=>{
    console.log("came here--------------------------",sessionStorage.getItem("b2LoggedIn"),localStorage.getItem("b2LoggedIn"))

    if(sessionStorage.getItem("b2LoggedIn")){
        localStorage.setItem('b2LoggedIn',true);
    }
    if(localStorage.getItem("b2LoggedIn")){
        sessionStorage.setItem('b2LoggedIn',true);
    }
    if(!localStorage.getItem('b2LoggedIn') && !sessionStorage.getItem("b2LoggedIn")){
        
        logOut();
    }
    getNotifications();
    getLastLoginDate();
  },[])

  const getNotifications=()=>{
    fetch(`${window._env_.AUTH_URL}/notifications/get-notifications`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log("api response",json);
        if (json.success) {
          let resourceNames=new Map();
          if(json?.data?.length>0){
            json?.data.map((obj)=>{
              if(obj.resourceName && obj.resolved===false){
                resourceNames.set(obj.resourceName.toUpperCase(),obj);
              }
            })
            const newOptions=CATEGORIES.map((obj)=>{
              if(resourceNames.has(obj.text.toUpperCase())){
                const value=resourceNames.get(obj.text.toUpperCase());
                obj.warning=value?.severity;
                if(obj.subCategories.length>0 && value?.subResourceName!==null && (typeof value?.subResourceName==='string')){
                  obj.subCategories.map((sub)=>{
                    if(sub.label.toUpperCase() === value.subResourceName.toUpperCase()){
                      sub.warning=value?.severity;
                    }
                  })
                }
              }
              return obj;
            })
            console.log("newoptions",newOptions);
            setMenuOptions(newOptions);
          }else{
            const newOptions=CATEGORIES.map((obj)=>{
              if(obj.warning){
                delete obj.warning;
              }
                if(obj.subCategories.length>0){
                  obj.subCategories.map((sub)=>{
                    if(sub.warning){
                      delete sub.warning;
                    }
                  })
                }
              return obj;
            })
            console.log("newoptions",newOptions);
            setMenuOptions(newOptions);
          }
        }
      }).catch((error)=>{
        console.log("ignore notification error",error);
      });
    /* call to fetch new dashboard */
    getDashboardUrl();
  }

  console.log('User management ui Routes re-rendered.');

  const getLandingPage = () => {
    console.log("landing page",permissions);
    const dashboardPermissions=["dashboard_keysegments","dashboard_journeyanalytics","dashboard_intent","dashboard_trends","dashboard_dataquality","dashboard_insights","account_dashboard"]
    if(permissions || (Array.isArray(permissions) && permissions.length)){
      if(permissions.find(perm => perm.resourceName.toLowerCase() === 'manual_data_upload')){
        if(permissions.filter(perm => dashboardPermissions.includes(perm.resourceName.toLowerCase()) || perm.resourceName.toLowerCase() === 'manual_data_upload').length > 1)
          return isNewDashboard ? newDashboardUrl : funnelUrl
        else 
          return manualDataUpload
      }
    }
    return isNewDashboard ? newDashboardUrl : funnelUrl
  }

  const logoutDialogHandler = (event, reason, isContinue) => {
    console.log("logoutDialogHandler reason", event, reason, isContinue);
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    if (alertDialog) {
      setAlertDialog(false);
      if (isContinue) {
        fetchReissueToken();
      } else {
        logOut();
      }
    }
    if (logoutDialog) {
      setAlertDialog(false);
      setLogoutDialog(false);
      logOut();
    }
  };
  
  const landingPage = getLandingPage();

  (function smartlookIdSet() {
    const b2bToken = localStorage.getItem("b2bToken");
    if (!window.smartlook?.idSet && b2bToken) {
      const decodedToken = jwt_decode(b2bToken);
      window.smartlook('identify', decodedToken?.userUuid, {
        name: decodedToken?.name,
        email: decodedToken?.email,
        tenant: decodedToken?.tenantId,
      })
      window.smartlook.__proto__.idSet = true;
    }
  }) ();

  return (
    <ThemeProvider theme={UITheme}>
      <div style={{display:"", position:"", height: "100%", width: "100%"}}>
        {logoutDialog && alertDialog ? (
            <LogoutDialog open={logoutDialog} setOpen={setLogoutDialog} logoutDialogHandler={logoutDialogHandler} />
          ) : alertDialog ? (
            <AlertDialog open={alertDialog} setOpen={setAlertDialog} setLogoutDialog={setLogoutDialog} logoutDialogHandler={logoutDialogHandler} />
          ) : (
            <></>
        )}
        <div
          className="headerCombined"
          style={{ position: "sticky", zIndex: 1000, top: 0,}}
        >
          <Header  menuOptions={menuOptions}  globalIcp={globalIcp} setGlobalIcp={setGlobalIcp} icpList={icpList} setIcpList={setIcpList} lastLoginTime={lastLoginTime} isMenuLoaded={isMenuLoaded} />
        </div>
        <div style={{marginLeft: "70px", width: "calc(100% - 70px)"}}>
          <Switch>
             <Route exact path="/">
              <Suspense fallback={ <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
                <CircularProgress style={{color:"#016B40"}}/> </div>} >
                <App tokenSetter={setToken} getNotifications={getNotifications}  setIsAuth={setIsAuth} setLastLoginTime={setLastLoginTime}   />
              </Suspense>
            </Route>

            <Route exact path="/no-access">
            <NoAccess />
            </Route>
            {/* {!permissions || (Array.isArray(permissions) && !permissions.length) || permissions.find(obj => {
              return obj.resourceName === "manual_data_upload"
            }) === undefined ?
              <Route path="/home">
                <ContentProvider
                  component={<IframeComponent url={funnelUrl} extra_param={params_url} icp={globalIcp} />}
                />
              </Route> :
              <Route path="/home">
                <ContentProvider
                  component={<IframeComponent url={manualDataUpload} extra_param={params_url} icp={globalIcp} />}
                />
              </Route>
            } */}
            {/* <Route exact path="/no-access" >
              
            </Route> */}

            <Route path="/home" >
            {isNewDashboard === undefined ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height:"100vh" }}>
                <CircularProgress style={{color:"#016B40"}}/>
              </div>
            ) : (
              <ContentProvider
                component={<IframeComponent url={landingPage} extra_param={params_url} icp={globalIcp} />}
              />
            )
            }
              
            </Route>

            <Route path="/monitoring_module">
              <ContentProvider
                component={<IframeComponent url={monitoring_module} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/enrichment-queue">
              <ContentProvider
                component={<IframeComponent url={enrichmentQueue} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/account-contact-creation">
              <ContentProvider
                component={<IframeComponent url={acc_contact_Creation} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/account-list-view">
              <ContentProvider
                component={<IframeComponent url={accountListView} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/account-detailed-view">
              <ContentProvider
                component={<IframeComponent url={accountListView} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/web-form-fill">
              <ContentProvider component={<IframeComponent url={webFormUrl} extra_param={params_url} icp={globalIcp} />} />
            </Route>
            <Route path="/unified-account-profile">
              <ContentProvider
                component={<IframeComponent url={unifiedAccountprofileUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/campaign-hub">
              <ContentProvider component={<IframeComponent url={campaignHubUrl} extra_param={params_url} icp={globalIcp} />}/>
            </Route>
            <Route path="/admin-settings">
              <ContentProvider component={<IframeComponent url={b2useradminUrl} extra_param={params_url} icp={globalIcp} />}/>
            </Route>
            <Route path="/campaign-manager">
              <ContentProvider
                component={<IframeComponent url={campaignManagerUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/export-manager">
              <ContentProvider
                component={<IframeComponent url={exportManagerUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/b2scoring">
              <ContentProvider
                component={<IframeComponent url={b2scoringUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/b2prospect">
              <ContentProvider
                component={<IframeComponent url={b2prospectUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/b2useradminui">
              <ContentProvider
                component={<IframeComponent url={b2useradminUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/b2analyticsreports">
              <ContentProvider
                component={<IframeComponent url={b2analyticsreportsUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/b2manualupload">
              <ContentProvider
                component={<IframeComponent url={b2manualuploadUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/icp-score-configuration">
              <ContentProvider
                component={<IframeComponent url={ICpScoreInfoUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/no-access-page">
              <ContentProvider
                component={<NoAccessComponent globalIcp={globalIcp} icpList={icpList}/>}
              />
            </Route>
            <Route path="/engagement-score-configuration">
              <ContentProvider
                component={<IframeComponent url={engagementScoreInfoUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/account-dashboard">
              {isNewDashboard === undefined ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height:"100vh" }}>
                  <CircularProgress style={{ color:"#016B40" }}/>
                </div>
              ) : (
                <ContentProvider
                  component={<IframeComponent url={isNewDashboard ? newDashboardUrl : funnelUrl} extra_param={params_url} icp={globalIcp} />}
                />
              )
              }
            </Route>
            <Route path="/trend-report">
              <ContentProvider
                component={<IframeComponent url={trendUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/account-management-summary">
              <ContentProvider
                component={<IframeComponent url={accountSummaryUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/zoho-setup">
              <ContentProvider component={<ZohoSetUp />} extra_param={params_url} icp={globalIcp} />
            </Route>
            <Route path="/zoho-sync">
              <ContentProvider component={<ZohoSync />} extra_param={params_url} icp={globalIcp} />
            </Route>
            <Route path="/zoho-callback">
              <ContentProvider component={<ZohoCallback />} extra_param={params_url} icp={globalIcp} />
            </Route>
            <Route path="/salesforce-setup">
              <ContentProvider component={<SalesforceSetUp />} />
            </Route>
            <Route path="/salesforce-sync">
              <ContentProvider component={<SalesforceSync />} />
            </Route>
            <Route path="/salesforce-callback">
              <ContentProvider component={<SalesforceCallback />} />
            </Route>
            <Route path="/sfactivities-setup">
              <ContentProvider component={<SFActitvitiesSetUp />} />
            </Route>
            <Route path="/sfactivities-sync">
              <ContentProvider component={<SFActivitiesSync />} />
            </Route>
            <Route path="/sfactivities-callback">
              <ContentProvider component={<SFActivitiesCallback />} />
            </Route>
            <Route path="/attribute-setup">
              <ContentProvider component={<ControlledAccordions />} extra_param={params_url} icp={globalIcp} />
            </Route>
            <Route path="/webhooks-setup">
              <ContentProvider
                component={<IframeComponent url={webhooksUrl} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
            <Route path="/integration-setup">
              <ContentProvider
                component={<IframeComponent url={integrationSetupUrl} extra_param={params_url} icp={globalIcp}  />}
              />
            </Route>
            <Route path="/oauth/mailchimp/callback">
              <ContentProvider
                component={<Redirect />}
              />
            </Route>
            <Route path="/manual_data_upload">
              <ContentProvider 
                component={<IframeComponent url={manualDataUpload} extra_param={params_url} icp={globalIcp} />}
              />
            </Route>
           
          </Switch>
      
        </div>
      
      </div>
    </ThemeProvider>
  );
}

export default RoutesManager;

// chmod +x ./env.sh && ./env.sh  && cp env-config.js ./public &&
